import React, { Component } from 'react';
import Layout from '../../layout';
import Banner from '../../components/banner/banner';
import Header from '../../components/header/header';
import Qsa from '../../components/qsa/qsa';
import GetToWork from '../../components/getToWork/get-to-work';
import Footer from '../../components/footer/footer';

import './index.scss';

class Index extends Component {

  render(){
    return (
      <Layout>
        <Banner></Banner>
        <Header></Header>
        <Qsa></Qsa>
        <div className="eventWorkHolder">
          <GetToWork></GetToWork>
        </div>
        <Footer></Footer>
      </Layout>
    );
  }
}

export default Index;
