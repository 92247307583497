import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from 'gatsby';

import Service_1 from '../../assets/images/qsa/service-6-1.jpg';
import Service_2 from '../../assets/images/qsa/service-6-2.jpg';
import Service_3 from '../../assets/images/qsa/service-6-3.jpg';
import Complain from '../../assets/images/qsa/complain.png';
import Tab from '../../assets/images/qsa/tab-1-1.jpg';
import Timeline from '../../assets/images/qsa/time-line.jpg';
import { Image } from 'react-bootstrap';
import './qsa.scss';

/* eslint-disable-next-line */


class Qsa extends Component {
  render(){
    var settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      speed: 500,
      slidesToScroll: 3,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    var testimonials = {
      dots: true,
      arrows: false,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
    };
    return (
      <div>
        <section className="kc-elm kc-css-283752 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-846806 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" service-title-box sec-pad-top">
                          <div className="container">
                              <div className="sec-title text-center light">
                                <span>Discover more</span>
                                <h2>List of all SAAS<br/>applications to match your needs</h2>
                              </div>
                          </div>
                        </section>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-532240 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-788436 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <div className=" service-style-seven">
                          <div className="container-outer">
                              <div className="container">
                                <div className="service-style-seven-carousel">
                                    <Slider {...settings}>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_1}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">QSA</a></h3>
                                                <p>QSA #1 Cloud base ERP system used by leading Industires in Pakistan. </p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_2}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Payroll</a></h3>
                                                <p>PMS makes it easy to onboard, pay, insure and support your hardworking team</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_3}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Complain Managment</a></h3>
                                                <p>Complain Managment system manage every channel in a single platform to track complaints, respond quickly & get insights to improve customer experience</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_1}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">School Managment System</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_2}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Petrol Pump Managment</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_3}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Warehouse Managment</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_1}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">QSA</a></h3>
                                                <p>QSA #1 Cloud base ERP system used by leading Industires in Pakistan.</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_2}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Payroll</a></h3>
                                                <p>PMS makes it easy to onboard, pay, insure and support your hardworking team</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <div className="single-service-style-seven hvr-sweep-to-bottom">
                                            <div className="img-box">
                                                <Image src={Service_3}className="attachment-post-thumbnail size-post-thumbnail wp-post-image"></Image>
                                            </div>
                                            <div className="text-block">
                                                <h3><a href="#">Complain Managment</a></h3>
                                                <p>Complain Managment system manage every channel in a single platform to track complaints, respond quickly & get insights to improve customer experience</p>
                                                <a href="#" className="read-more">Learn More</a>
                                            </div>
                                          </div>
                                      </div>
                                    </Slider>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-934886 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-599092 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" time-tab-style-one sec-pad-bottom">
                          <br/>
                          <div className="single-service-proj">
                              <div className="container">
                                <div className="sec-title text-center">
                                    <span></span>
                                    <h2>Complaint Management system</h2>
                                </div>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12">
                                      <div className="img-box">
                                        <Image src={Complain}></Image>
                                      </div>
                                      <br/>
                                    </div>
                                    <div className="col-xl-7 col-lg-12">
                                      <div className="text-box">
                                          <p>
                                            Solve your complaint management issues with fully customized software and experts who will walk you through each step. We provide full, ongoing support. Talk with an expert ready to help customize a software solution to manage complaints and improve customer and team-member relationships. We have deployed complaint management solutions to leading organizations & companies. Your unique challenges are important. 
                                          </p>
                                          <ul className="list-items">
                                          </ul>
                                          <br/>
                                          <div className="aaa mt-3">
                                            <Link to="complain" className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim">
                                              <span className="arrow-btn pointer-large animsition-link">Read More</span>
                                            </Link>
                                          </div>
                                      </div>
                                      <br/>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <br/>
                          <br/>
                          <div className="single-service-proj">
                              <div className="container">
                                <div className="sec-title text-center">
                                    <span></span>
                                    <h2>Quick Simple Accounting</h2>
                                </div>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12">
                                      <div className="img-box">
                                        <Image src={Tab}></Image>
                                      </div>
                                      <br/>
                                    </div>
                                    <div className="col-xl-7 col-lg-12">
                                      <div className="text-box">
                                          <p>
                                            QSA #1 Cloud base ERP system used by leading Industires in Pakistan. Cloud and on-premise, with open access from anywhere around the globe. Complete Accounting Module with more than 50 report, Inventory Module to get complete track of raw material and finish goods, Sale & Purchase module etc. A complete one solution for Industires and large scale companies. 
                                          </p>
                                          <br/>
                                          <div className="aaa mt-3">
                                            <Link to="accounting" className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim" data-animation="fade-anim">
                                              <span className="arrow-btn pointer-large animsition-link">Read More</span>
                                            </Link>
                                          </div>
                                      </div>
                                      <br/>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <br/>
                          <br/>
                          <div className="single-service-proj">
                              <div className="container">
                                <div className="sec-title text-center">
                                    <span></span>
                                    <h2>PAYROLL Management</h2>
                                </div>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12">
                                      <div className="img-box">
                                        <Image src={Tab}></Image>
                                      </div>
                                      <br/>
                                    </div>
                                    <div className="col-xl-7 col-lg-12">
                                      <div className="text-box">
                                          <p>
                                            Human Resource & Payroll Management Software. PMS makes it easy to onboard, pay, insure and support your hardworking team. 
                                          </p>
                                          <ul className="list-items">
                                          </ul>
                                          <br/>
                                          <div className="aaa mt-3">
                                            <Link to="payroll" className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim">
                                              <span className="arrow-btn pointer-large animsition-link">Read More</span>
                                            </Link>
                                          </div>
                                      </div>
                                      <br/>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <br/>
                          <br/>
                          <div className="single-service-proj d-none">
                              <div className="container">
                                <div className="sec-title text-center">
                                    <span></span>
                                    <h2>School Management System Software </h2>
                                </div>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12">
                                      <div className="img-box">
                                          <Image src={Tab}></Image>
                                      </div>
                                      <br/>
                                    </div>
                                    <div className="col-xl-7 col-lg-12">
                                      <div className="text-box">
                                          <p>
                                            Besides the obvious of it being a complex piece of computer software, School Management Software is an administration tool for educational institutions. This classification comes from the fact that this type (or category) of software aims to help educational organizations in their daily routine, by automating administrative tasks. “School management software” is also known as a “School management system” or a “Student information system”. These systems provide functionality from applications and class enrollment to detailed performance monitoring and financials. 
                                          </p>
                                          <ul className="list-items">
                                          </ul>
                                          <div className="aaa mt-3">
                                            <a href="./sms-software.php" data-animation-child="" className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim" data-animation="fade-anim">
                                            <span className="arrow-btn pointer-large animsition-link">Read More</span>
                                            </a>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </section>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-537572 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-3316 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" cta-six text-center gray-bg sec-pad">
                          <div className="container">
                              <div className="sec-title text-center">
                                <h2>Any Questions <br/> Let’s talk.</h2>
                              </div>
                              <a href="#" className="thm-btn">Request a free quote</a>
                              <br/>
                          </div>
                        </section>
                        <br/>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-752232 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-340943 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" time-line-style-one">
                          <div className="container">
                              <div className="row">
                                <div className="col-lg-6 d-flex">
                                    <div className="text-block my-auto">
                                      <div className="sec-title light">
                                          <span>company history</span>
                                          <h2>How we started company <br/> QSA</h2>
                                      </div>
                                      <ul className="nav nav-tabs tab-title clearfix" role="tablist">
                                          <li className="nav-item">
                                            <a className="nav-link active" href="#year1" role="tab" data-toggle="tab">1995</a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" href="#year2" role="tab" data-toggle="tab">2000</a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" href="#year3" role="tab" data-toggle="tab">2006</a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" href="#year4" role="tab" data-toggle="tab">2010</a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" href="#year5" role="tab" data-toggle="tab">2019</a>
                                          </li>
                                      </ul>
                                      <div className="tab-content">
                                          <div role="tabpanel" className="tab-pane fade show active" id="year1">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et odio felis. Maecenas et tempor lectus, nec bibendum eros. Nam interdum tempor lacus, at ultrices quam efficitur vitae. Fusce pellentesque tellus nisl, et mattis lacus elementum eu
                                            </p>
                                          </div>
                                          <div role="tabpanel" className="tab-pane fade" id="year2">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et odio felis. Maecenas et tempor lectus, nec bibendum eros. Nam interdum tempor lacus, at ultrices quam efficitur vitae. Fusce pellentesque tellus nisl, et mattis lacus elementum eu
                                            </p>
                                          </div>
                                          <div role="tabpanel" className="tab-pane fade" id="year3">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et odio felis. Maecenas et tempor lectus, nec bibendum eros. Nam interdum tempor lacus, at ultrices quam efficitur vitae. Fusce pellentesque tellus nisl, et mattis lacus elementum eu
                                            </p>
                                          </div>
                                          <div role="tabpanel" className="tab-pane fade" id="year4">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et odio felis. Maecenas et tempor lectus, nec bibendum eros. Nam interdum tempor lacus, at ultrices quam efficitur vitae. Fusce pellentesque tellus nisl, et mattis lacus elementum eu
                                            </p>
                                          </div>
                                          <div role="tabpanel" className="tab-pane fade" id="year5">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et odio felis. Maecenas et tempor lectus, nec bibendum eros. Nam interdum tempor lacus, at ultrices quam efficitur vitae. Fusce pellentesque tellus nisl, et mattis lacus elementum eu
                                            </p>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="col-lg-6">
                                    <div className="featured-image-box">
                                      <Image src={Timeline}></Image>
                                    </div>
                                </div>
                                <br/>
                              </div>
                          </div>
                        </section>
                        <br/>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-65345 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-221171 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" faq-style-two sec-pad">
                          <div className="container">
                              <div className="sec-title text-center">
                                <span>FAQS</span>
                                <h2>Frequently asked questions <br/> answers</h2>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="single-faq-two">
                                      <div className="text-block">
                                          <h3><a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h3>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                          <a href="#" className="read-more">Read More</a>
                                      </div>
                                      <div className="title-block">
                                          <div className="inner-box">
                                            <div className="icon-box"><i className="fa fa-question"></i></div>
                                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="single-faq-two">
                                      <div className="text-block">
                                          <h3><a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h3>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                          <a href="#" className="read-more">Read More</a>
                                      </div>
                                      <div className="title-block">
                                          <div className="inner-box">
                                            <div className="icon-box"><i className="fa fa-question"></i></div>
                                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="single-faq-two">
                                      <div className="text-block">
                                          <h3><a href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a></h3>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.</p>
                                          <a href="#" className="read-more">Read More</a>
                                      </div>
                                      <div className="title-block">
                                          <div className="inner-box">
                                            <div className="icon-box"><i className="fa fa-question"></i></div>
                                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </section>
                    </div>
                  </div>
              </div>
            </div>
        </section>
        <section className="kc-elm kc-css-502321 kc_row">
            <div className="kc-row-container">
              <div className="kc-wrap-columns">
                  <div className="kc-elm kc-css-567874 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                        <section className=" testimonials-style-four sec-pad">
                          <div className="container">
                              <br/>
                              <ul className="slider">
                                <Slider {...testimonials}>
                                  <li className="slide-item">
                                      <div className="single-testimonial-four">
                                        <p>&#8220;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.&#8221;</p>
                                        <h3>&#8211; Syed Manzar</h3>
                                        <span>owner</span>
                                      </div>
                                      <br/>
                                  </li>
                                  <li className="slide-item">
                                      <div className="single-testimonial-four">
                                        <p>&#8220;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.&#8221;</p>
                                        <h3>&#8211; Syed Manzar</h3>
                                        <span>owner</span>
                                      </div>
                                      <br/>
                                  </li>
                                  <li className="slide-item">
                                    <div className="single-testimonial-four">
                                      <p>&#8220;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam interdum tempor lacus.&#8221;</p>
                                      <h3>&#8211; Syed Manzar</h3>
                                      <span>owner</span>
                                    </div>
                                    <br/>
                                </li>
                                </Slider>
                              </ul>
                          </div>
                        </section>
                    </div>
                  </div>
              </div>
            </div>
        </section>
      </div>
    );
  }
}

export default Qsa;
